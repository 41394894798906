import React from "react";
import dcv from "dcv";
import "@awsui/global-styles/index.css";
import { DCVViewer } from "dcv-ui";

const LOG_LEVEL = dcv.LogLevel.INFO;
const BASE_URL = process.env.REACT_APP_DCV_FILES_PATH;

let auth, serverUrl, credentials;
console.log("Using NICE DCV Web Client SDK version" + dcv.version.versionStr);

function App() {
  const [authenticated, setAuthenticated] = React.useState(false);
  const [sessionId, setSessionId] = React.useState("");
  const [authToken, setAuthToken] = React.useState("");
  const [message, setMessage] = React.useState("");

  const onSuccess = React.useCallback((_, result) => {
    const { sessionId, authToken } = { ...result[0] };
    console.log("Authentication successful.");
    setSessionId(sessionId);
    setAuthToken(authToken);
    setAuthenticated(true);
    setMessage("");
  }, []);

  const submitCredentials = React.useCallback(() => {
    const username = credentials[2];
    const password = credentials[3];
    if (username && password) {
      auth.sendCredentials({ username, password });
    } else {
      setMessage("Invalid Credentials");
    }
  }, []);

  const onPromptCredentials = React.useCallback(() => {
    submitCredentials();
  }, [submitCredentials]);

  const onError = React.useCallback((_, error) => {
    console.log("Error during the authentication: " + error.message);
  }, []);

  const params =
    typeof window !== undefined
      ? new URLSearchParams(window.location.search)
      : { token: "" };
  const token = params.get("token");
  const userId = params.get("user_db_id");

  const authenticate = React.useCallback(() => {
    if (token) {
      setMessage("Validating Session. Please wait");
      fetch(
        `${process.env.REACT_APP_BASE_PROJECT_SERVICE_ENDPOINT}/resource/credentials/`,
        {
          method: "POST",
          body: JSON.stringify({
            launch_token: token,
            ...(userId && { user_db_id: userId }),
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data?.response) {
            credentials = data?.data?.launch_string.split(" ");
            const id = credentials[1];
            const formattedLink = "https://" + id + ".postud.io:8443";
            dcv.setLogLevel(LOG_LEVEL);
            serverUrl = formattedLink.toString();
            console.log("Starting authentication with", serverUrl);
            if (id) {
              auth = dcv.authenticate(serverUrl, {
                promptCredentials: onPromptCredentials,
                error: onError,
                success: onSuccess,
              });
            }
          } else {
            throw Error(data?.errormessage);
          }
        })
        .catch((err) => {
          setMessage(err?.message || "Invalid Cloud Session ID");
        });
    } else {
      setMessage("No Session ID Present");
    }
  }, [token, userId, onPromptCredentials, onError, onSuccess]);

  React.useEffect(() => {
    authenticate();
  }, [authenticate]);

  React.useEffect(() => {
    let interval = null;
    if (userId) {
      interval = setInterval(() => authenticate(), 60 * 5 * 1000); // 5 minutes
    }
    return () => {
      clearInterval(interval);
    };
  }, [userId, authenticate]);

  const handleDisconnect = (reason) => {
    console.log(
      "Disconnected: " + reason.message + " (code: " + reason.code + ")"
    );
    auth.retry();
    setMessage(`Disconnected: ${reason.message} with code ${reason.code}`);
    setAuthenticated(false);
  };

  if (authenticated) {
    return (
      <DCVViewer
        dcv={{
          sessionId: sessionId,
          authToken: authToken,
          serverUrl: serverUrl,
          baseUrl: BASE_URL,
          onDisconnect: handleDisconnect,
          logLevel: LOG_LEVEL,
        }}
        uiConfig={{
          toolbar: {
            visible: true,
            fullscreenButton: true,
            multimonitorButton: true,
          },
        }}
      />
    );
  }

  if (!authenticated && message) {
    return <p>{message}</p>;
  }

  return null;
}

export default App;
